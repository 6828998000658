//news article card
.article.card {
    width: 100%!important;
    background-color: white;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    border: none;

    img {
        transition: transform .5s ease;
    }

    &:hover {
        img {
            transform: scale(1.05);
        }
    }

    .card-img-wrapper {
        overflow: hidden;
    }

    .category {
        background: $primary;
        color: white;
        padding: 5px;
        text-align: center;
        font-size: 14px;
    }

    h5 {
        margin-bottom: 10px;
        font-weight: bold;
        color: $primary;
        text-align: center;
        display: -webkit-box;
        vertical-align: super;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .date {
        text-align: center;
        color: #717171;
        font-size: 13px;
        margin-bottom: 20px;
    }

    p {
        line-height: 1.5;
        text-align: justify;
        margin-bottom: 20px;
    }
}

//menu cover card
.menu-cover {
    padding: 100px 0;
    background: no-repeat url("/build/src/img/menu-cover-bg.png");
    background-size: cover;

    .col-lg-4 {
        margin-bottom: 50px;
    }

    .menu-cover-card {
        height: 100%;
        
        .img-mask {
            overflow: hidden;
            position: relative;
        }

        .card-image {
            transition: transform ease 0.5s;
        }

        .read-more {
            height: 100%;
            width: 100%;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: opacity 0.5s ease;
            z-index: 2;

            img {
                width: 80px;
                height: 80px;
            }

        }

        &:hover {
            .card-image {
            transform: scale(1.05);
            }

            .read-more {
                opacity: 1;
            }
        }
    }

    h5 {
        color: $primary;
        text-align: center;
        font-weight: 600;
        letter-spacing: 0.1em;
    }

    .card-text {
        margin-bottom: 30px;
    }
}

.branch-card {
    margin-bottom: 30px;
    
    .branch-card-wrapper {
        padding: 30px;
        background: white;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    }

    .title {
        margin-bottom: 50px;

        h5 {
            color: $primary;
            margin-bottom: 10px;
        }

        h2 {
            font-weight: 600;
        }
    }

    .branch-detail {
        div {
            display: flex;
            margin-bottom: 20px;
            align-items: baseline;
        }

        a {
            color: $content;
            text-decoration: underline;

            &:hover {
                color: $primary;
            }
        }

        i {
            color: $primary;
            margin-right: 10px;
        }

        p {
            margin-bottom: 0;
        }
    }

    .branch-link {
        text-align: right;
        

        @include media-breakpoint-down(sm) {
            text-align: left;
        }
    }
}