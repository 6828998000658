.section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;

    img {
        max-width: 60px;
        margin-right: 15px;
    }

    h1 {
        margin-bottom: 0;
    }
}

.subheader {
    background: center no-repeat url('/build/src/img/subheader-bg.png');
    background-size: cover;
    padding: 4rem 0;

    @include media-breakpoint-down(sm) {
        padding: 2em 0;
    }

    .section-title {
        margin: 0;
    }
}