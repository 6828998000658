#main {
    background: $primary;
    align-items: center;
    z-index: 10;
    height: 80px;

    .hamburger  {
        img {
            transition: opacity .5s ease;
            opacity: 1;
            width: 30px;
            height: 30px;
        }

        i {
            transition: opacity .5s ease;
            opacity: 0;
            position: absolute;
            left: 26px;
            color: white;
            font-size: 30px;
            font-weight: normal;
        }
    }

    .hamburger.active {
        img {
            opacity: 0;
        }

        i {
            opacity: 1;
        }

    }

    #logo {
        @include media-breakpoint-down(lg) {
            & {
                margin-right: 1em;
            }
        }
    }

    .desktop-menu {
        display: flex;

        @include media-breakpoint-down(lg) {
            & {
                display: none;
            }
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;
            display: flex;
            align-items: center;

            li {
                margin: 0 10px;
    
                a {
                    color: white;
                    padding: 4px 20px;
                    border: 2px solid white;
                    border-radius: 50px;
                    letter-spacing: 0.05em;

                    &:hover {
                        color: $primary;
                        background: white;
                    }
                }
            }
        }
    }

    #navBarMobile {
        position: absolute;
        left: 0;
        top: 80px;
        background: white;
        height: 100vh;
        width: 270px;
        transform: translateX(-270px);
        transition: ease 0.3s; 
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);

        .nav-remove {
            background: $primary;
            height: 80px;
            display: flex;
            align-items: center;
            padding: 1em;
        }

        ul {
            padding: 0;
            margin-top: 20px;

            li {
                display: grid;
                text-align: center;
                padding: 10px 0;
    
                a {
                    color: $content;
                    padding: 10px;

                    &:hover {
                        color: $primary;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    #navBarMobile.active {
        transform: translateX(0);
        transition: ease 0.3s; 
    }
}
