.home {
    .popup-msg {
        display: none;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0,0,0,0.5);
    }

    .popup-msg.show {
        display: block;
        position: fixed;
        z-index: 500;

        .popup-content-wrapper {
            position: relative;
            align-items: center;
            margin: auto;
            padding: 20px;
            display: grid;
            height: 100%;
        }

        .popup-content-inner-wrapper {
            position: relative;
            width: max-content;
            margin: auto;
            max-width: 800px;
            height: auto;
        }

        .popup-remove {
            position: absolute;
            z-index: 300;
            top: -20px;
            right: -20px;
            cursor: pointer;
            color: $primary;
            font-size: 50px;

            &:after {
                content: '';
                display: block;
                width: 45px;
                height: 45px;
                background: white;
                position: absolute;
                top: 3px;
                left: 1px;
                border-radius: 50px;
                z-index: -1;
            }

        }

        .popup-content {
            border: 10px solid $primary;
            border-radius: 5px;
            background: $primary;
            padding: 0;
            overflow: hidden;

            @media only screen and (max-width: 576px) {
                max-width: 400px;
            }

            @media only screen and (max-width: 450px) {
                max-width: 330px;
                border: 5px solid $primary;
            }
        }
    }

    marquee {
        background: no-repeat url('/build/src/img/marquee-bg.png');
        color: $content;
        font-size: 20px;
        font-weight: 600;
        line-height: 46px;
        letter-spacing: 0.05em;
        display: flex;
        align-items: center;
        height: 100px;
        background-size: cover;

        a {
            color: $content;

            &:hover {
                color: $primary;
                text-decoration: underline;
            }
        }

        ul {
            display: flex;
            list-style-position: outside;
            margin-bottom: 0;

            li {
                &:before{
                    content: url('/build/src/img/marquee-bullets.png');
                    display: inline-block;
                    margin: 0 20px;
                }
            }
        }
    }


    #news {
        padding-top: 85px;
        padding-bottom: 100px;

        @include media-breakpoint-up(lg) {
            border-bottom: 60px solid $primary;
        }

        @include media-breakpoint-down(lg) {
            padding-top: 55px;
        }

        @include media-breakpoint-down(sm) {
            padding-bottom: 60px;

            .swiper-container {
                padding-bottom: 20px;
            }

            .card-text {
                display: -webkit-box;
                vertical-align: super;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }
        }
    }

    #menu {
        background: no-repeat url('/build/src/img/home-menu-bg.png');
        background-size: cover;
        position: relative;
        padding-bottom: 100px;

        @include media-breakpoint-down(md) {
            background: none;
        }

        .menu-title {
            background: $secondary;
            display: flex;
            flex-direction: column-reverse;
            text-align: center;
            padding: 20px;

            .en {
                font-size: 1.5em;
                position: relative;
                left: 7px;
                color: white;
                opacity: 0.35;
                font-weight: bold;
                letter-spacing: 0.5em;
            }

            .ch {
                font-size: 2.5em;
                color: white;
                font-weight: bold;
                letter-spacing: 0.08em;
            }

            @include media-breakpoint-up(lg) {
                position: absolute;
                padding: 50px 164px 300px 30px;

                .meal-detail-wrapper {
                    position: relative;
                }

                .en {
                    font-size: 70px;
                    line-height: 100px;
                    letter-spacing: 10px;
                    transform: rotate(90deg);
                    position: absolute;
                    top: 150px;
                    left: -60px;
                }

                .ch {
                    font-size: 100px;
                    position: relative;
                    left: 60px;
                    max-width: 300px;
                }
            }
        }

        .meal-detail-wrapper {
            text-align: left;
            margin-bottom: 100px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 30px;

                .meal-description p {
                    display: -webkit-box;
                    vertical-align: super;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }

            .meal-title {
                color: $primary;
                font-size: 2.5rem;
                letter-spacing:  0.1em;
                font-weight: bold;
                display: grid;
                margin-bottom: 20px;
    
                span {
                    font-size: 1.5rem;
                }
            }
        }

        .feature-meal {
            .col-lg-10 {
                padding: 0!important;
            }

            @include media-breakpoint-up(lg) {
                padding-top: 200px;
                margin-bottom: 100px;

                .meal-detail-wrapper {
                    text-align: right;
                }

                .meal-description {
                    margin-bottom: 60px;
                }

                .col-lg-6 {
                    margin-left: auto;
                }

                .col-lg-10 {
                    margin-left: auto;
                }
            }

            @include media-breakpoint-down(md) {
                .meal-detail-wrapper {
                    display: flex;
                    flex-direction: column-reverse;
                }

                .meal-description p {
                    text-align: justify;
                }
            }
        }

        .secondary-meal {
            @include media-breakpoint-up(sm) {
                .meal-detail-wrapper:nth-of-type(odd) {
                    flex-direction: row-reverse;
                }
            }

            @include media-breakpoint-down(sm) {
                .meal-img{
                    padding: 0!important;
                }
            }

            .meal-description  p {
                text-align: justify;
            }
        }

        .meal-text {
            padding: 20px;
        }
    }

    .more-menu {
        display: block;
        width: 100%;
        padding: 20px;
        font-size: 18px;
        background-color: $secondary;
        text-align: center;
        color: #000;
        font-weight: bold;
        letter-spacing: 0.05em;

        &:hover {
            background-color: $accent;
            text-decoration: none;
        }
    }

    .mobile-link {
        color: $primary;

        a {
            color: $primary;
            text-decoration: underline;
        }

        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}