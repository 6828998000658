.join-us {
    .join-wrapper {
        padding: 100px 0;
    }

    .join-inner-wrapper {
        padding: 20px;

        a {
            padding: 20px;
            display: block;
            box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
        }

        .thumbnail {
            max-width: 100px;
        }

        @include media-breakpoint-down(sm) {
            padding: 25px 50px;
        }
    }

    .social-wrapper {
        margin-bottom: 60px;
        justify-content: space-around;

        .icon-wrapper {
            max-width: 300px;
            margin: auto;
            padding: 20px 0 40px;
        }

        .content-wrapper {
            text-align: center;

            label {
                color: $primary;
                font-size: 20px;
                margin-bottom: 15px;
            }
        }
    }

    //hover效果
    .join-inner-wrapper.wowprime:hover .icon-wrapper, .join-inner-wrapper.line:hover .icon-wrapper {
        transform: scale(1.1);
        transition: transform 0.5s ease;
    }

    .join-inner-wrapper.wowprime .icon-wrapper, .join-inner-wrapper.line .icon-wrapper {
        transition: transform 0.5s ease;
    }
}