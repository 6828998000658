.about-us {
    .about-us-intro {
        min-height: 900px;

        @include media-breakpoint-down(lg) {
            min-height: 600px;
        }

        .main {
            background: no-repeat center center url("/build/src/img/about-us-main.png");
            background-size: cover;
            padding: 8em 10em;

            @include media-breakpoint-down(lg) {
                padding: 8em 6em;
            }

            @include media-breakpoint-down(lg) {
                padding: 5em 5em;
                min-height: 500px;
            }

            .main-wrapper {
                display: grid;
                align-items: center;
                height: 100%;
            }

            .main-inner-wrapper {
                max-width: 500px;
            }

            h1 {
                margin-bottom: 20px;
            }

            h4 {
                color: $primary;
                margin-bottom: 20px;
            }
        }

        .secondary {
            .row {
                height: 50%;

                .text {
                    .content{
                        display: flex;
                        align-items: center;
                        height: 100%;
                        padding: 2em;
                    }
                }

                @include media-breakpoint-down(lg) {
                    min-height: 300px;
                }

                @include media-breakpoint-down(sm) {
                    .col-md-6 {
                        min-height: 300px;
                    }
                }
            }

            .row:first-of-type {
                .food {
                    background: no-repeat center center url("/build/src/img/carrot.png");
                    background-size: cover;
                }

                .text {
                    background: $secondary;
                    width: 100%;

                    h4, p {
                        color: white;
                    }

                    h4 {
                        margin-bottom: 20px;
                    }
                }
            }

            .row:nth-of-type(2) {
                .food {
                    background: no-repeat center center url("/build/src/img/onion.png");
                    background-size: cover;
                }

                .text {
                    .content-inner-wrapper {
                        display: grid;
                        margin: auto;

                        div {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            right: 20px;
                        }
                    }
                
                        img {
                            width: 45px;
                            margin: 15px;
                        }

                        p {
                            margin: 0;
                        }
                }

                @include media-breakpoint-down(sm) {
                    flex-direction: column-reverse;
                }
            }
        }
    }

    .about-us-detail {
        @include media-breakpoint-down(xl) {
            .about-us-detail-item {
                min-height: 500px;
            }
        }

        @include media-breakpoint-up(md) {
            .about-us-detail-item:nth-of-type(even) {
                flex-direction: row-reverse;
            }
        }

        .image-bg {
            background-size: cover!important;

            @include media-breakpoint-down(md) {
                min-height: 300px;
            }
        }

        .text {
            background: no-repeat center url("/build/src/img/about-us-text-bg.png");
            background-size: cover;
            padding: 5em;

            h4 {
                color: $primary;
                margin-bottom: 15px;
            }
    
            p {
                margin-bottom: 0;
                text-align: justify;
            }

            .content {
                display: flex;
                align-items: center;
                height: 100%;
            }

            .content-inner-wrapper {
                max-width: 500px;
                margin: auto;
            }
        }
    }
}