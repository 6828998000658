.contact-us {
    .contact-us-content {
        padding: 50px 0 100px;
    }

    .contact-info-wrapper {
        margin-bottom: 40px;
    }

    .note {
        margin-bottom: 60px;
    }

    .contact-info {
        margin-bottom: 45px;

        h5 {
            color: $primary;
            margin-bottom: 10px;
        }

        p {
            line-height: 1.5;
        }

        a {
            color: $content;

            &:hover {
                color: $primary;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .container {
            padding-right: 30px;
            padding-left: 30px;
        }
    }
}