@charset "UTF-8";

/*! *****************************************
/*    css
/*    V 2021.7.25
/*        by LOHAS Anya
/******************************************/

/*
*layout
* global
*/

@import "../../node_modules/bootstrap/scss/bootstrap";

/*========================================================================*\
    > abstracts
\*========================================================================*/
@import "abstracts/_variables";

/*========================================================================*\
    > base
\*========================================================================*/
@import "base/_typography";
@import "base/_utilities";


/*========================================================================*\
    > layout
\*========================================================================*/
@import "layout/_header";
@import "layout/_footer";


/*========================================================================*\
    > components
\*========================================================================*/
@import "components/_card";
@import "components/_swiper";
@import "components/_button";
@import "components/_title";

/*========================================================================*\
    > pages
\*========================================================================*/
@import "pages/_home";
@import "pages/_news";
@import "pages/_blog";
@import "pages/_join-us";
@import "pages/_404";
@import "pages/_menu";
@import "pages/_menu-regular";
@import "pages/_branch";
@import "pages/_contact-us";
@import "pages/_about-us";

/*========================================================================*\
    > global
\*========================================================================*/

@import "_global";