.news {
    .section-title {
        animation: fadeIn 0.7s ease-out both;
        opacity: 0;
        position: relative;

        @keyframes fadeIn {
            from {
                opacity: 0;
                top: -100px;
            }
            to {
                opacity: 1;
                top: 0;
            }
        }
    }


    .news-content-wrapper {
        padding-bottom: 100px;

        .grid {
            display: grid;
            grid-gap: 25px;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            grid-auto-rows: 24px;

            .article.card {
                height: fit-content;
            }
        }
    }
}