.error404 {
    #content {
        background: center no-repeat url('/build/src/img/404-bg.png');
        background-size: cover;
    }

    .error-message-wrapper {
        display: grid;
        justify-content: center;
        text-align: center;
        padding: 100px 0;

        .title {
            font-size: 26px;
            font-weight: 600;
            line-height: 1.7;
        }

        .subtitle {
            line-height: 2;
        }
    }

    .more {
        margin-top: 40px;
    }
}