#popup {
    .swiper-button-prev, .swiper-button-next {
        background: rgba(0,0,0,0.3);
        width: 60px;
        height: 60px;


        &:hover {
            background: $primary;
        }

        &:after {
            color: white;
            transform: scale(0.5);
        }

        @include media-breakpoint-down(lg) {
            width: 45px;
            height: 45px;
        }
    }

    .swiper-button-next {
        right: 0;
    }

    .swiper-button-prev {
        left: 0;
    }
}

#banner {
    position: relative;
    
    @include media-breakpoint-up(sm) {
        .mobile-banner {
            display: none;
        }
    }

    @include media-breakpoint-down(sm) {
        .desktop-banner {
            display: none;
        }

        .mobile-banner {
            display: block
        }
    }

    .swiper-slide {
        height: auto;
    
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    
    .swiper-button-prev, .swiper-button-next {
        background: $primary;
        width: 60px;
        height: 60px;
    
        &:after {
            color: white;
            transform: scale(0.5);
        }
    }
    
    .swiper-button-prev {
        position: absolute;
        left: 0;
        top: unset;
        bottom: 0px;
    }
    
    .swiper-button-next {
        position: absolute;
        left: 60px;
        top: unset;
        bottom: 0px;
    }
}

#news {
    .swiper-container {
        margin-bottom: 40px;
        padding-bottom: 60px;
        overflow: hidden;
    }

    .swiper-button-prev, .swiper-button-next {
        background: $primary;
        width: 60px;
        height: 60px;
        z-index: 1;

        &:after {
            color: white;
            transform: scale(0.5);
        }

        @include media-breakpoint-down(lg) {
            width: 45px;
            height: 45px;
        }
    }

    .swiper-button-prev {
        position: absolute;
        left: 0;
    }

    .swiper-button-next {
        position: absolute;
        right: 0;
    }

    @include media-breakpoint-down(sm) {
        .swiper-slide {
            padding: 0 30px;
        }
    }
}

.menu-flip {
    .swiper-container {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
    }

    .swiper-button-prev, .swiper-button-next {
        background: rgba(0,0,0,0.3);
        width: 60px;
        height: 60px;


        &:hover {
            background: $primary;
        }

        &:after {
            color: white;
            transform: scale(0.5);
        }

        @include media-breakpoint-down(lg) {
            width: 45px;
            height: 45px;
        }
    }

    .swiper-button-next {
        right: 0;
    }

    .swiper-button-prev {
        left: 0;
    }

    @media only screen and (min-width: 769px) {
        .swiper-slide:first-child {
          transition: transform 100ms;
        }

        .swiper-slide:first-child img {
          transition: box-shadow 500ms;
        }

        .swiper-slide.swiper-slide-active:first-child {
          transform: translateX(50%);
          z-index: 2;
        }

        .swiper-slide:nth-child(2) {
          transition: transform 100ms;
        }

        .swiper-slide.swiper-slide-next:nth-child(2) {
          transform: translateX(55%);
          z-index: 1;
        }

        .swiper-container[dir="rtl"]
          .swiper-slide.swiper-slide-active:first-child {
          transform: translateX(-50%);
        }

        .swiper-container[dir="rtl"]
          .swiper-slide.swiper-slide-next:nth-child(2) {
          transform: translateX(-55%);
        }
    }

    @media only screen and (min-width: 1600px) {
        .swiper-slide:first-child {
          transition: transform 100ms;
        }

        .swiper-slide:first-child img {
          transition: box-shadow 500ms;
        }

        .swiper-slide.swiper-slide-active:first-child {
          transform: translateX(140%);
          z-index: 2;
        }

        .swiper-slide:nth-child(2) {
          transition: transform 100ms;
        }

        .swiper-slide.swiper-slide-next:nth-child(2) {
          transform: translateX(140%);
          z-index: 1;
        }

        .swiper-container[dir="rtl"]
          .swiper-slide.swiper-slide-active:first-child {
          transform: translateX(-50%);
        }

        .swiper-container[dir="rtl"]
          .swiper-slide.swiper-slide-next:nth-child(2) {
          transform: translateX(-55%);
        }
    }

    @media only screen and (max-width: 768px) {
        .swiper-slide {
            padding: 0 30px;
        }
    }
}