button {
    background: transparent;
    border: none;
}

.btn.primary {
    background-color: $primary;
    padding: 10px 40px;
    color: white;
    border-radius: 0;

    &:hover {
        background-color: $secondary;
    }
}

.btn.secondary {
    background-color: $secondary;
    padding: 10px 40px;
    color: #000;
    border-radius: 0;

    &:hover {
        background-color: $accent;
    }
}

.btn.white {
    background-color: white;
    padding: 8px 25px;
    color: $primary;
    border: 2px solid $primary;
    border-radius: 50px;
    margin-bottom: 10px;
    margin-right: 5px;
    margin-left: 5px;

    &:hover {
        background: $primary;
        color: white;
    }
}

.more {
    display: flex;
    justify-content: center;
}