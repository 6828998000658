.blog {
    .blog-wrapper {
        padding: 100px 0;
    }

    .blog-title-wrapper {
        align-items: center;
        margin-bottom: 40px;

        .blog-meta-wrapper {
            @include media-breakpoint-down(lg) {
                margin-bottom: 30px;
            }
        }

        .date {
            margin-bottom: 15px;
        }

        .title {
            color: $primary;
            padding-bottom: 10px;
            border-bottom: 1px solid #C9CACA;
            margin-bottom: 15px;
            font-size: 30px;
            font-weight: bold;
        }

        .excerpt {
            color: $content;
            line-height: 2;
            font-size: 18px;
        }
    }

    .blog-content-wrapper {
        margin-bottom: 60px;

        p {
            color: $content;
            line-height: 2;
        }

        label {
            color: $primary;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px;
        }
    }

    @include media-breakpoint-down(lg) {
        .blog-content-wrapper, .blog-meta-wrapper, .blog-image-cover-wrapper {
            padding: 0 40px;
        } 
    }

    @include media-breakpoint-down(sm) {
        .blog-btn {
            text-align: center;
        }
    }
}