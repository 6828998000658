.menu {
    .subheader {
        background: no-repeat center url("/build/src/img/menu-subheader.png");
        background-size: cover;
        padding: 8em 0;

        @include media-breakpoint-down(sm) {
            padding: 3em 0;
        }

        img {
            filter: brightness(0) invert(1);
        }
        
        h1 {
            color: white;
        }
    }
}