/*============General Set up============*/
html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    overflow-x: hidden;
    box-sizing: border-box;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch
}

body {
    margin: 0;
    font-family: 'Rubik','Noto Sans TC', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans TC", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
    color: #212529;
    text-align: left;
    background-color: #fff;
    overflow-x: hidden;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
    letter-spacing: 0.1rem;
    color: $content;
}

img {
    width: 100%;
}

ul {
    list-style-type: none;
}

a:hover {
    text-decoration: none;
}

#content {
    margin-top: 80px; //fix header height
    min-height: calc(100vh - 80px);
    position: relative;
    padding-bottom: 312px;

    @include media-breakpoint-down(sm) {
        padding-bottom: 550px;
    }
}

#logo img {
    max-height: 30px;
}

@media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1300px;
    }
}