.tab {
    padding: 80px 0 40px;

    ul {
        padding-left: 0;
        column-count: 4;
        column-gap: 0;

        li {
            text-align: center;
            background: $primary;
            
            a {
                background: $primary;
                color: white;
                display: block;
                padding: 10px;

                &:hover {
                    background: $primary-accent;
                }

                &.active {
                    background: $secondary;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding-right: 30px;
        padding-left: 30px;
    }
}

.note {
    margin: auto;

    p {
        color: $primary;
        text-align: justify;
        line-height: 1.5;
    }
}