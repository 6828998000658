.branch {
    .tab {
        padding: 40px 0;
    }

    .branch-content-wrapper {
        @include media-breakpoint-down(sm) {
            padding: 0 40px;
        }
    }
}